import { Override } from "framer"

export function DynamicTextOverride(): Override {
    const currentYear = new Date().getFullYear()
    const dynamicText = `© ${currentYear}`
    const staticText = "AQUA-GLOBE Kkt. - Minden jog fenntartva!"

    return {
        text: `${dynamicText} | ${staticText}`,
    }
}
